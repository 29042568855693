import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./contact.css";
import { Newnav } from "./Newnav";

const ContactUs = () => {
  const form = useRef();
  const [isSent, setIsSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_bdffdem",
        "template_alyd",
        form.current,
        "user_KIP4sbmL9itetpkyz3R88"
      )
      .then(
        (result) => {
          setIsSent(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        />
      </head>
      <Newnav />
      <span className="form-title">Contact Us</span>
      {isSent ? (
        <div className="thankYouMessage">
          <h2>Thank You!</h2>
          <p>
            Your message has been sent successfully. We will get back to you
            shortly.
          </p>
          <a href="/">Home</a>
        </div>
      ) : (
        <form ref={form} onSubmit={sendEmail} className="form">
          <label>Name</label>
          <input type="text" name="user_name" />
          <label>Email</label>
          <input type="email" name="user_email" />
          <label>Message</label>
          <textarea name="message" />
          <input type="submit" value="Send" />
        </form>
      )}
    </div>
  );
};

export { ContactUs };
