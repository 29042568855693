import styled from "styled-components";
import BannerImage from "./images/bg.jpg";
import Divider from "./images/divider.jpg";

export const NavBar = styled.div`
  height: 100px;
  background-image: linear-gradient(rgba(7, 49, 32, 1), rgba(255, 0, 0, 0));
  display: grid;
  width: 100%;
  z-index: 999;
  position: fixed;
  grid-template-columns: 20% 20% 20% 20% 20%;
  top: 0;
`;

export const NavItem = styled.div`
  color: white;
  line-height: 100px;
  justify-self: center;
  a {
    text-decoration: none !important;
  }
  a:visited {
    color: white;
  }
`;

export const Banner = styled.div`
  background-image: url(${BannerImage});
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: -114px;
`;

export const BannerText = styled.div`
  font-size: 5rem;
  color: white;
  position: relative;
  font-weight: 600;
  top: 355px;
  p {
    margin: 0;
  }
  @media (max-width: 600px) {
    font-size: 3rem;
  }
`;

export const ImageShowcase = styled.div`
  display: flex;
  gap: 54px;
  padding: 55px 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 20px;
    gap: 20px;
  }
`;

export const ImageContainer = styled.div`
  height: 500px;
  width: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    height: 400px;
  }
`;

export const DividerImage = styled.div`
  background-image: url(${Divider});
  height: 450px;
  width: 100%;
  background-size: cover;
  background-position: top 885px right 0;
  @media (max-width: 768px) {
    height: 150px;
  }
`;

export const BioSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 60px;
`;

export const BioTitle = styled.span`
  padding: 60px 0;
  width: 40%;
  font-size: 4rem;
  display: block;
  color: #bd4f09;
  text-align: left;
  position: relative;
  left: 250px;
  @media (max-width: 600px) {
    margin: 0 auto;
    left: 0px;
  }
`;

export const BioPerson = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  flex-direction: ${(p) => (p.$reverse ? "row-reverse" : "row")};
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 75%;
    align-self: center;
  }
`;

export const BioImageContainer = styled.div`
  flex: 1 1 40%;
  img {
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const BioInfo = styled.div`
  flex: 1 1 60%;
  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
    text-align: ${(p) => (p.$reverse ? "right" : "left")};
  }
  @media (max-width: 768px) {
    p {
      text-align: left;
    }
  }
`;

export const Footer = styled.div`
  padding: 20px 0;
`;
