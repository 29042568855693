import React from "react";
import "./grid.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const PhotoGrid = ({ media }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 601 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <Carousel responsive={responsive} itemClass="carousel-item-padding-40-px">
      {media.map((item, index) => (
        <div className="media-item" key={index}>
          {item.type === "image" && (
            <img src={item.url} alt={`Media ${index + 1}`} />
          )}
          {item.type === "video" && (
            <video controls>
              <source src={item.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          {item.type === "carousel" && (
            <img src={item.url} alt={`Media ${index + 1}`} />
          )}
        </div>
      ))}
    </Carousel>
  );
};

export default PhotoGrid;
