import React, { useEffect, useState } from "react";
import "./App.css";
import { Fade } from "react-awesome-reveal";

import {
  Banner,
  BannerText,
  ImageShowcase,
  DividerImage,
  BioSection,
  BioTitle,
  BioPerson,
  BioInfo,
  Footer,
  ImageContainer,
  BioImageContainer,
} from "./styles";
import img1 from "./images/1.jpg";
import img2 from "./images/2.jpg";
import img3 from "./images/3.jpg";
import wBio from "./images/winnie.jpeg";
import dBio from "./images/daniel.jpeg";
import cBio from "./images/collin.jpeg";
import PhotoGrid from "./PhotoGrid";
import { Newnav } from "./Newnav";

const App = () => {
  const [media, setMedia] = useState([]);

  useEffect(() => {
    const fetchInstagramMedia = async () => {
      try {
        const response = await fetch(
          "https://alyd-backend.vercel.app/api/instagram"
        ); // Fetch from backend API
        const data = await response.json();
        setMedia(data); // Set media data from backend
      } catch (error) {
        console.error("Error fetching Instagram media:", error);
      }
    };

    fetchInstagramMedia();
  }, []);

  return (
    <div className="App">
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        />
      </head>
      <Newnav />
      <Banner>
        <Fade cascade damping={0.1}>
          <BannerText>
            <p>CREATING MEMORABLE</p>
            <p>MOMENTS TOGETHER</p>
          </BannerText>
        </Fade>
      </Banner>
      <ImageShowcase>
        <ImageContainer>
          <img src={img1} alt="img1" />
        </ImageContainer>
        <ImageContainer>
          <img src={img2} alt="img2" />
        </ImageContainer>
        <ImageContainer>
          <img src={img3} alt="img3" />
        </ImageContainer>
      </ImageShowcase>
      <DividerImage />
      <BioTitle>Meet the Team.</BioTitle>
      <BioSection>
        <BioPerson>
          <BioImageContainer>
            <img src={dBio} alt="person 1"></img>
          </BioImageContainer>
          <BioInfo>
            <p>
              Meet Daniel, your dude behind the lens at A-LYD Visuals
              Photography. Daniel’s friendly approach and eye for detail will
              make every client feel at ease, resulting in stunning, authentic
              photos. Based in Metro Vancouver, he loves to explore and find the
              perfect backdrop for each shot. Daniel’s knack for making people
              feel comfortable in front of the camera results in heartfelt and
              candid photos that reflect genuine emotions.{" "}
            </p>
            <p>
              When Daniel isn’t behind the camera, you will find him exploring
              new locations, actively engaging in sports, or enjoying time with
              family. For a photography experience that’s as enjoyable as it is
              professional, choose A-LYD Visuals Photography. Let’s have some
              fun and create beautiful memories together.
            </p>
          </BioInfo>
        </BioPerson>
        <BioPerson $reverse>
          <BioImageContainer>
            <img src={wBio} alt="person 2"></img>
          </BioImageContainer>
          <BioInfo $reverse>
            <p>
              Winnie, born and raised in Vancouver, BC, has been a photography
              enthusiast from a young age, always captivated by the art of
              capturing moments. Her lens is drawn to the beauty of nature,
              expressions in portraits, and the sleek lines of cars. Despite her
              full-time job as a licensed optician, Winnie’s heart belongs to
              photography, a passion that fuels her creative spirit.
            </p>
            <p>
              Beyond her love for photography, she is a car enthusiast, finding
              joy in their blend of form and function. Her love for pets and
              travel adds a layer of warmth and adventure to her personality,
              making her a delightful companion on any journey. Winnie’s
              friendly nature and love for meeting new people make her a natural
              at forming meaningful connections wherever she goes.
            </p>
          </BioInfo>
        </BioPerson>
        <BioPerson>
          <BioImageContainer>
            <img src={cBio} alt="person 3"></img>
          </BioImageContainer>
          <BioInfo>
            <p>
              Collin was born in North Vancouver and raised in Whistler, BC.
              Living in such a small city, Collin spent a lot of his younger
              years skiing in the mountains, biking along trails with his dad,
              and adventuring the great outdoors.
            </p>
            <p>
              Fast forward to 2024, Collin now lives in Surrey and focuses his
              time working as a Realtor with Oakwyn Realty Encore in Coquitlam,
              working for a fruit company that’s also in Coquitlam, and now
              photography as well.
            </p>
            <p>
              Photography has always been a passion for Collin and was also the
              gateway in getting him to start travelling. He’s been to many
              places like Hong Kong, Japan, Taiwan, Iceland, Ireland, and many
              parts of the United States and Canada.
            </p>
            <p>
              Collin enjoys capturing those special moments during proposals and
              weddings but also enjoys capturing the great outdoors and real
              estate!
            </p>
          </BioInfo>
        </BioPerson>
        {/* ig */}
        {/* <Insta /> */}
      </BioSection>
      <PhotoGrid media={media} />
      <Footer>{/* <img src={ins} alt="instagram" width={20} /> */}</Footer>
    </div>
  );
};

export default App;
