import React from "react";
import "./price_2.css";
import { Newnav } from "./Newnav";

const PricingPage = () => {
  return (
    <div>
      <header>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap"
          rel="stylesheet"
        ></link>
      </header>

      <Newnav />
      <div class="pricing-page">
        <section class="pricing-section">
          <h2>WEDDING PHOTOGRAPHY PACKAGES</h2>
          <div class="pricing-cards">
            <div class="pricing-card">
              <h3>Package 1</h3>
              <p class="price">Starting from $3000</p>
              <p>100 Edited Photos</p>
              <p>6 hours</p>
              <p>Online Gallery (1 year)</p>
            </div>
            <div class="pricing-card">
              <h3>Package 2</h3>
              <p class="price">Starting from $4500</p>
              <p>150 Edited Photos</p>
              <p>8 hours shoot</p>
              <p>Online Gallery (1 year)</p>
            </div>
            <div class="pricing-card">
              <h3>Package 3</h3>
              <p class="price">Starting from $6000</p>
              <p>200 Edited Photos</p>
              <p>10 hours shoot</p>
              <p>Online Gallery (1 year)</p>
            </div>
          </div>
        </section>
        <div className="spacing"></div>
        <section class="pricing-section">
          <h2>ENGAGEMENT PACKAGES</h2>
          <div class="pricing-cards two">
            <div class="pricing-card">
              <h3>Package 1</h3>
              <p class="price">Starting from $300</p>
              <p>20 Edited Photos</p>
              <p>1 hour shoot</p>
              <p>Online Gallery (1 year)</p>
            </div>
            <div class="pricing-card">
              <h3>Package 2</h3>
              <p class="price">Starting from $350</p>
              <p>25 Edited Photos</p>
              <p>1.5 hours shoot</p>
              <p>Online Gallery (1 year)</p>
            </div>
          </div>
        </section>
        <div className="spacing"></div>
        <section class="pricing-section">
          <h2>REAL ESTATE PACKAGES</h2>
          <div class="pricing-cards two">
            <div class="pricing-card">
              <h3>Package 1</h3>
              <p class="price">Starting from $250</p>
              <p>20 Edited Photos</p>
              <p>1 hour shoot</p>
              <p>Online Gallery (1 year)</p>
            </div>
            <div class="pricing-card">
              <h3>Package 2</h3>
              <p class="price">Starting from $500</p>
              <p>30 Edited Photos</p>
              <p>1 hour shoot</p>
              <p>Online Gallery (1 year)</p>
              <p>1 Minute Drone Video</p>
              <p>Video Walk Through</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export { PricingPage };
