import React from "react";
import "./newnav.css";

const Newnav = () => {
  return (
    <nav class="navbar">
      <div class="navbar-left">
        <a href="/" class="navbar-brand">
          alydvisuals
        </a>
      </div>
      <div class="navbar-right">
        <a href="/pricing">Pricing</a>
        <a href="/contact-us">Contact Us</a>
      </div>
    </nav>
  );
};

export { Newnav };
